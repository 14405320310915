import { createYAxis } from "./profileDurationComponents/yAxis";
import { createXAxis } from "./profileDurationComponents/xAxis";
import { createAreas } from "./profileDurationComponents/areas";
import { createGradient } from "./profileDurationComponents/gradient";
import { createProfileDuration } from "./profileDurationComponents/profileDuration.js";
import { createBestThreshold, createLastXDaysThreshold } from "./profileDurationComponents/threshold.js";
import { addMask } from "./profileDurationComponents/mask.js";
import { createTooltip } from "./profileDurationComponents/tooltip.js";
import { pointerLeft, pointerMoved } from "./profileDurationComponents/pointerEvents.js";

function drawPowerProfileChart() {
    const xAxisValues = powerProfileData.xAxis;
    const yLabel = powerProfileData.yLabel;
    const unit = powerProfileData.unit;
    const relativeUnit = powerProfileData.relativeUnit;
    const profileType = 'power';

    const profileDurations = powerProfileData.profileDurations;
    const thresholds = powerProfileData.thresholds;

    const baseData = profileDurations[0].profileDuration;
    const bisect = d3.bisector(d => d.interval).center;
    const bisectorY = d3.bisector(d => d.value).center;

    d3.select("#power-profile-chart").select("svg").remove();

    var container = document.getElementById('power-profile-chart');

    const margin = {top: 10, right: 10, bottom: 30, left: 55};

    var width = parseInt(container.clientWidth);

    var height = parseInt(0.5 * width);

    if (width < 740) {
        height = parseInt(0.7 * width);
    }

    var svg = d3.select(container)
        .append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("preserveAspectRatio", "xMaxYMax meet")
            .append("g")
                .attr("transform", `translate(${margin.left}, ${margin.top})`)
            .on("touchstart", event => event.preventDefault());

    var yScale = createYAxis({
        svg: svg, 
        baseData: baseData, 
        margin: margin, 
        height: height, 
        label: yLabel
    });

    var xScale = createXAxis({
        svg: svg, 
        baseData: baseData, 
        xAxisValues: xAxisValues, 
        margin: margin, 
        height: height, 
        width: width
    });

    createAreas({
        svg: svg, 
        baseData: baseData, 
        bisect: bisect,
        xScale: xScale,
        yScale: yScale,
        margin: margin,
        height: height
    });

    createGradient({
        svg: svg, 
        baseData: baseData, 
        xScale: xScale,
        yScale: yScale,
        margin: margin,
        height: height
    });

    createProfileDuration({
        svg: svg, 
        profileDurations: profileDurations,
        xScale: xScale,
        yScale: yScale,
        showProfileModel: true
    });

    var sortedBest = profileDurations[0].profileDuration.slice().sort((a, b) => a.value - b.value);

    // #region CP
    if (thresholds[0]?.thresholds?.eCP[0]?.value) {
        createBestThreshold({
            svg: svg,
            height: height,
            xScale: xScale,
            yScale: yScale,
            bisectorY: bisectorY,
            sortedBest: sortedBest,
            thresholds: thresholds[0].thresholds.eCP[0],
            thresholdAcronym: thresholds[0].thresholds.eCP[0].thresholdAcronym,
            unit: unit,
            relativeUnit: relativeUnit,
            textAnchor: "end"
        });
    }
    // #endregion
    
    // #region MAP
    if (thresholds[0]?.thresholds?.eMAP[0]?.value) {
        createBestThreshold({
            svg: svg,
            height: height,
            xScale: xScale,
            yScale: yScale,
            bisectorY: bisectorY,
            sortedBest: sortedBest,
            thresholds: thresholds[0].thresholds.eMAP[0],
            thresholdAcronym: thresholds[0].thresholds.eMAP[0].thresholdAcronym,
            unit: unit,
            relativeUnit: relativeUnit,
            textAnchor: "middle"
        });
    }
    // #endregion

    // #region FTP
    if (thresholds[0]?.thresholds?.eFTP[0]?.value) {
        createBestThreshold({
            svg: svg,
            height: height,
            xScale: xScale,
            yScale: yScale,
            bisectorY: bisectorY,
            sortedBest: sortedBest,
            thresholds: thresholds[0].thresholds.eFTP[0],
            thresholdAcronym: thresholds[0].thresholds.eFTP[0].thresholdAcronym,
            unit: unit,
            relativeUnit: relativeUnit,
            textAnchor: "start"
        });
    }
    // #endregion

    // #region VT1
    if (thresholds[0]?.thresholds?.eVT1[0]?.value) {
        createBestThreshold({
            svg: svg,
            height: height,
            xScale: xScale,
            yScale: yScale,
            bisectorY: bisectorY,
            sortedBest: sortedBest,
            thresholds: thresholds[0].thresholds.eVT1[0],
            thresholdAcronym: thresholds[0].thresholds.eVT1[0].thresholdAcronym,
            unit: unit,
            relativeUnit: relativeUnit,
            textAnchor: "middle"
        });
    }
    // #endregion

    //#region Tooltip
    var mask =addMask({
        svg: svg,
        width: width,
        height: height,
        pointerMoved: pointerMoved,
        pointerLeft: pointerLeft
    });

    var {tooltip, tooltipLine} = createTooltip({
        svg: svg,
        tooltipId: "tooltip",
        margin: margin,
        height: height
    });

    mask.on("pointerenter pointermove", (event) => pointerMoved({
            event: event, 
            xScale: xScale, 
            yScale: yScale, 
            baseData: baseData, 
            profileDurations: profileDurations, 
            profileType: profileType,
            bisect: bisect, 
            tooltip: tooltip, 
            tooltipLine: tooltipLine, 
            tooltipId: "tooltip",
            width: width, 
            showProfileModel: true,
            unit: unit
        }))
        .on("pointerleave", (event) => pointerLeft({
            event: event, 
            tooltip: tooltip, 
            tooltipLine: tooltipLine,
            tooltipId: "tooltip"
        }));
    //#endregion

    return svg.node();
}

let resizeTimer;

d3.select(window).on("resize", function() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
        drawPowerProfileChart();
    }, 200);
});

drawPowerProfileChart();